<template>
  <div class="staff backMenu">
    <div class="sch">
      <div>
        <el-input
          v-model="api.platformAccountName"
          placeholder="账号"
          class="sch-input"
        ></el-input>
        <el-input
          v-model="api.mobile"
          placeholder="手机号"
          class="sch-input"
          type="number"
        ></el-input>
        <el-select v-model="api.status" placeholder="请选择状态" clearable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            
          >
          </el-option>
        </el-select>
        <el-button @click="seeAbout">查询</el-button>
      </div>
      <el-button>创建员工</el-button>
    </div>
    <div
      class="merch-table scorr-roll"
    >
      <div>
        <el-table
          :data="userList.data"
          border
          style="width: 100%"
          header-cell-class-name="table_header"
        >
          <el-table-column prop="id" label="序号" align="center" width="80">
          </el-table-column>
          <el-table-column prop="shop_name" label="创建时间" align="center">
          </el-table-column>
          <el-table-column
            prop="enterprise"
            label="员工姓名"
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="type_name"
            label="绑定账号"
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="mobile"
            label="手机号"
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="性别"
            align="center"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="状态"
            align="center"
            width="80"
          >
          </el-table-column>

          <el-table-column
            prop="address"
            label="操作"
            align="center"
            width="250"
          >
            <template slot-scope="scope">
              <div class="userBtn">
                <!--  -->
                <div @click="toRedact(scope.row)">详情</div>
                <div @click="toRedact(scope.row)">编辑</div>
                <div @click="toRedact(scope.row)">禁用</div>
                <div @click="toRedact(scope.row)">删除</div>
                <!-- <div @click="toAccount">账户</div>
                  <div>详情</div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="userList.total"
        :page-size="userList.per_page"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("power", "getMenuList"); //权限vuex公共库
export default {
  data() {
    return {
      userList: {
        data: [],
        total: null,
      },
      api: {
        platformAccountName: "",
        mobile: "",
        status: null,
      },
      options: [
        {
          value: 0,
          label: "禁用",
        },
        {
          value: 1,
          label: "启用",
        },
      ],
    };
  },
  props: {
    choseId: {
      type: Number,
      default: function () {
        return;
      },
    },
  },
  created() {},
  methods: {
    seeAbout() {
    },
    handleCurrentChange(){

    }
  },
};
</script>

<style lang="scss" scoped>
.staff {
  padding: 0 24px;
  box-sizing: border-box;
  display: block;
}
.sch {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 22px 0;
  box-sizing: content-box;
  > div {
    display: flex;
    height: 30px;
  }
  button {
    padding: 0 12px;
    background-color: #06b7ae;
    color: #fff;
    border: none;
    height: 30px;
    margin-left: 20px;
  }
}
.merch-page {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: right;
}
.sch-input {
  width: 220px;
  height: 32px;
  margin-right: 20px;
}
::v-deep .el-input--suffix {
  height: 32px;
}
::v-deep .el-input__inner {
  width: 100%;
  height: 100%;
}
::v-deep .el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  background-color: #f2f3f5 !important;
}
::v-deep .el-table--border {
  border-radius: 10px;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
</style>
